<template>
  <div class="home">
    <Header />
    <section>
      <div id="about" class="container-experience border-container">
        <div class="experience-img">
          <div class="bk-pettine">
            <span
              >come te <br />
              nessuno mai</span
            >
          </div>
          <img alt="pettine" src="../assets/pettine.webp" />
        </div>
        <div class="experience-desc container-fluid">
          <div class="two-img row">
            <div class="col-12 col-md-6 first-img">
              <div class="bk-tinta"></div>
              <img src="../assets/tinta.webp" alt="" />
            </div>
            <div class="col-12 col-md-6 second-img">
              <div class="bk-professionista"></div>
              <img src="../assets/professionista.svg" alt="" />
              <div class="circle hideDesktop"></div>
            </div>
            <p class="third-mobile">
              In uno dei quartieri più vivaci e interessanti di Milano,
              NONPAREIL è anche vetrina per giovani artisti che vogliono esporre
              le loro opere in un luogo che parla un linguaggio unico, fatto su
              misura per te.
            </p>
            <!-- <div class="hideDesktop staff">
              <h1>
                yoandy <br />
                gomez alfonso
              </h1>
              <p class="first">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Molestiae voluptate, voluptatem modi repellat omnis aliquam
                perspiciatis officia debitis dignissimos obcaecati nam dolor
                iste? Odit consequatur dicta, aperiam natus doloribus inventore!
              </p>
            </div> -->
            <!--  <div class="bk-tinta col-12"><div class=""></div></div>
            <div class="bk-professionista col-12"><div></div></div> -->
          </div>
          <div class="row">
            <div class="description p-0 col-12 mt-5">
              <h1>
                NONPAREIL <br class="hideDesktop" />
                experience
              </h1>
              <p class="first">
                è l’espressione di una nuova identità dedicata a bellezza, stile
                e benessere… senza eguali.
              </p>
              <p class="second">
                Un posto in cui riscoprire la tua personalità, affidandoti a
                passione, talento e professionalità, servizi esclusivi e
                personalizzati, in un ambiente dai contorni originali e
                inconfondibili. <br />
              </p>
              <p class="third-desk">
                In uno dei quartieri più vivaci e interessanti di Milano,
                NONPAREIL è anche vetrina per giovani artisti che vogliono
                esporre le loro opere in un luogo che parla un linguaggio unico,
                fatto su misura per te.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="slider">
        <div class="circle hideMobile"></div>
        <Carrousel />
      </div>
      <p class="slogan my-4">
        VIENI A TROVARCI, SCEGLI IL TUO LOOK<br />
        E VIVI UN’ESPERIENZA NONPAREIL!
      </p>
      <!-- <div id="esperienze" class="container-fluid border-container">
        <div class="row">
          <div class="col-12 col-md-12 col-lg-6 review">
            <h1>
              what about <br />
              your experience...
            </h1>
            <div class="angolo">
              <img src="../assets/angolo.svg" alt="angolo" />
            </div>
          </div>
          <div class="col-12 carousel-review">
            <Review :experiences="experiences" />
          </div>
        </div>
      </div> -->

      <!-- experience mobile -->
      <!-- <div class="banner hideDesktop">
        <p>enjoy your experience</p>
      </div> -->
      <div id="services">
        <div class="discover">
          <h2>
            DISCOVER<br />
            OUR SERVICES
          </h2>
          <svg
            width="195"
            height="44"
            viewBox="0 0 195 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 2H193V42"
              stroke="#E26B17"
              stroke-width="3"
              stroke-linecap="round"
            />
          </svg>
        </div>
        <div class="servizi-lista">
          <h1>SERVIZI STILISTICI</h1>
          <h1>PER IL TUO GIORNO PERFETTO</h1>
          <h1>TRATTAMENTI</h1>
          <h1>SERVIZI TECNICI</h1>
          <h1>CAPELLI BIANCHI</h1>
          <h1>UOMO</h1>
          <h1>BAMBINO</h1>
          <h1>CONSULENZE</h1>

          <a href="NONMENU_NONPAREIL.pdf" download="NONMENU_NONPAREIL"
            >DOWNLOAD NONMENU</a
          >

          <p>
            SCARICA IL NONMENU PER SCORPIRE I NOSTRI SERVIZI.<br /><br />
            PER QUALSIASI SERVIZIO, CONSULENZA O APPUNTAMENTO SONO RICHIESTI
            NOMINATIVO E RECAPITO TELEFONICO. TUTTI I DATI FORNITI SARANNO
            TRATTATI NEL PIENO RISPETTO DEL REGOLAMENTO UE 679/2016 E DEL D.LGS
            196/2003
          </p>
        </div>
      </div>

      <div id="il_blog">
        <Blog />
      </div>
      <!-- <div class="d-flex justify-content-center">
        <div class="box-orario">
          <p class="text-uppercase mb-2">
            dal lunedì al venerdi <br />
            9:00 - 19:00
          </p>
          <p class="text-uppercase">
            sabato <br />
            10:00 - 18:00
          </p>
        </div>
      </div> -->
    </section>
    <div id="contacts">
      <Footer />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Carrousel from "@/components/Carrousel.vue";
/* import Review from "@/components/Review.vue"; */
import Blog from "@/components/Blog.vue";
import axios from "axios";

export default {
  name: "Home",
  data() {
    return {
      experiences: [],
    };
  },
  async mounted() {
    try {
      let response = await axios.get("https://nonpareil.it/api/experiences");
      this.experiences = response.data;
    } catch (err) {
      console.log(err);
    }
  },
  components: {
    Header,
    Footer,
    Carrousel,
    /* Review, */
    Blog,
  },
};
</script>
