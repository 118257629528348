<template>
  <div class="blog">
    <div class="container">
      <div class="row title">
        <div class="col-12">
          <img class="non" src="../assets/non.svg" alt="" />
          <h3>blog</h3>
        </div>
      </div>
    </div>
    <div class="border-container">
      <swiper class="swiper" ref="swiper" :options="swiperOption">
        <swiper-slide
          v-for="(blog, index) in orderBlogs"
          :key="blog.id"
          class="box-blog"
        >
          <div class="article">
            <h3 v-html="blog.titolo"></h3>
            <p v-html="blog.text"></p>
            <div class="disposizione">
              <div v-if="blog.green_text" class="container-special">
                <span class="green-text" v-html="blog.green_text"></span>
              </div>
              <div v-if="blog.read_more" class="testo-nascosto">
                <span
                  @click="orderBlogs[index].opened = !orderBlogs[index].opened"
                  class="scopri"
                  v-if="!orderBlogs[index].opened"
                  >Read More</span
                >
                <span
                  @click="orderBlogs[index].opened = !orderBlogs[index].opened"
                  class="scopri"
                  v-if="orderBlogs[index].opened"
                  >Read Less</span
                >
                <p v-show="blog.opened" id="altro" class="" v-html=" blog.read_more">
                </p>
              </div>
              <div v-if="blog.picture" class="box-image">
                <img :src="'/contents/' + blog.picture" alt="" />
              </div>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import axios from "axios";

export default {
  name: "swiper-example-responsive-breakpoints",
  title: "Responsive breakpoints",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      blogs: [],
      orderBlogs: [],
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 40,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          1124: {
            slidesPerView: 4,
            spaceBetween: 12,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 12,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
    };
  },
  async mounted() {
    const app = this;
    app.blogs = [];
    const articoli = await axios
      .get("https://nonpareil.it/api/nonblog")
      .catch((e) => {
        alert(e);
      });
    articoli.data.forEach((element) => {
      element.opened = false;
      app.orderBlogs.unshift(element);
      app.orderBlogs.sort(function (a, b) {
        return new Date(b.id).getTime() - new Date(a.id).getTime();
      });
    });
    /* articoli.data.forEach((el) => {
      el.opened = false;
      app.orderBlogs.push(element);
    }); */
  },
  methods: {
    scopri() {
      document.getElementById("altro").classList.toggle("nascosto");
    },
  },
};
</script>

<style lang="scss" scoped></style>
