<template>
  <div class="b">
    <div class="container-fluid footer">
      <div class="row justify-space-between">
        <div class="col-12 col-md-2 col-lg-2 logo">
          <img class="hideMobile" alt="logo" src="../assets/logo.svg" />
          <div class="hideDesktop logo-footer">
            <img src="../assets/logo-footer.svg" alt="" />
            <img class="nnp" src="../assets/nonpareil-footer.svg" alt="" />
            <img src="../assets/text-footer.svg" alt="" />
          </div>
        </div>
        <div
          class="
            col-9 col-md-9
            
            col-lg-9
            
            p-0
            footer-list
            d-flex
            justify-content-between
          "
        >
          <ul>
            <li>
              <a href="https://goo.gl/maps/tSbmyPNM6b82WC2b8" target="_blank">
                Via Del Caravaggio 21<br />
                20144, Milano
              </a>
            </li>
          </ul>
          <ul>
            <li>
              <a href="mailto:info@nonpareil.it">info@nonpareil.it</a>
            </li>
            <li>
              <a href="tel:+39 3497050612">+39 3497050612</a>
            </li>
            <li>
              <a href="tel:+39 0236563887">+39 0236563887</a>
            </li>
          </ul>
          <ul class="social">
            <li>
              <a
                href="https://www.instagram.com/nonpareil_milano/"
                target="_blank"
                >instagram</a
              >
            </li>


          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style></style>
