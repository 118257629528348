<template>
  <div class="header container-fluid border-container">
    <div class="row">
      <div class="logo col-6 col-md-4 col-lg-5">
        <img alt="logo" src="../assets/logo.svg" />
        <img class="hideMobile" alt="name" src="../assets/name.svg" />
        <img class="hideMobile" alt="cit" src="../assets/cit.svg" />
      </div>
      <div class="nav col-6 col-md-4 col-md-4 offset-md-4 col-lg-5 offset-lg-2">
        <nav>
          <ul class="navigation">
            <li>
              <a href="#about">about</a>
            </li>

            <li><a href="#services">services</a></li>
            <li><a href="#experience">experiences</a></li>
            <li><a href="#il_blog">nonblog</a></li>
            <li class="hideDesktop"><a href="#contacts">contacts</a></li>
          </ul>
          <ul class="social hideMobile">
            <li class="hideMobile"><a href="#contacts">contacts</a></li>
            <li>
              <a
                href="https://www.instagram.com/nonpareil_milano/"
                target="_blank"
              >
                instagram
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      langs: ["en", "it"],
      langBool: false,
      open: false,
    };
  },
};
</script>

<style></style>
