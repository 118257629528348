<template>
  <div class="border-carrousel">
    <swiper class="swiper" :options="swiperOption">

      <swiper-slide class="box-img"
        ><img src="../assets/SLIDER/1.webp" alt=""
      /></swiper-slide>

      <swiper-slide class="box-img"
        ><img src="../assets/SLIDER/8.webp" alt=""
      /></swiper-slide>

      <swiper-slide class="box-img"
        ><img src="../assets/SLIDER/4.webp" alt=""
      /></swiper-slide>

      <swiper-slide class="box-img"
        ><img src="../assets/SLIDER/7.webp" alt=""
      /></swiper-slide>

      <swiper-slide class="box-img"
        ><img src="../assets/SLIDER/16.webp" alt=""
      /></swiper-slide>

      <swiper-slide class="box-img"
        ><img src="../assets/SLIDER/5.webp" alt=""
      /></swiper-slide>

      <swiper-slide class="box-img"
        ><img src="../assets/SLIDER/10.webp" alt=""
      /></swiper-slide>

      <swiper-slide class="box-img"
        ><img src="../assets/SLIDER/11.webp" alt=""
      /></swiper-slide>

      <swiper-slide class="box-img"
        ><img src="../assets/SLIDER/17.webp" alt=""
      /></swiper-slide>

      <swiper-slide class="box-img"
        ><img src="../assets/SLIDER/12.webp" alt=""
      /></swiper-slide>

      <swiper-slide class="box-img"
        ><img src="../assets/SLIDER/13.webp" alt=""
      /></swiper-slide>

      <swiper-slide class="box-img"
        ><img src="../assets/SLIDER/19.webp" alt=""
      /></swiper-slide>

      <swiper-slide class="box-img"
        ><img src="../assets/SLIDER/18.webp" alt=""
      /></swiper-slide>

      <swiper-slide class="box-img"
        ><img src="../assets/SLIDER/3.webp" alt=""
      /></swiper-slide>

      <swiper-slide class="box-img"
        ><img src="../assets/SLIDER/15.webp" alt=""
      /></swiper-slide>

      <div class="swiper-button-prev" slot="button-prev">
        <img src="../assets/sx.svg" alt="" />
      </div>
      <div class="swiper-button-next" slot="button-next">
        <img src="../assets/dx.svg" alt="" />
      </div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "swiper-example-navigation",
  title: "Navigation",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        autoplay: true,
        autoplayTimeout:2500,
        speed:1500,
        loop:true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 960px) {
  .swiper-button-next {
    right: 20px;
    transform: rotate(90deg);
    display: none;
  }

  .swiper-button-prev {
    left: 20px;
    transform: rotate(90deg);
    display: none;
  }
}
</style>
